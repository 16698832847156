import { post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { GET_WORKER_UNVERIFIED_SHIFTS_PATH } from "@src/appV2/redesign/WorkerShifts/UnverifiedShifts/useWorkerUnverifiedShifts";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

export const setShiftTimesheetAvailabilityRequestSchema = z.object({
  shiftId: z.string(),
  reason: z.string(),
  agentName: z.string(),
  agentId: z.string(),
});
export type SetShiftTimesheetAvailabilityRequest = z.infer<
  typeof setShiftTimesheetAvailabilityRequestSchema
>;

export const setShiftTimesheetAvailabilityResponseSchema = z.object({
  success: z.boolean(),
  response: z.object({
    _id: z.string(),
    timecardNotAvailable: z.object({
      reason: z.string(),
      setBy: z.string(),
      updatedAt: z.string(),
    }),
  }),
  error: z.string().nullable(),
});
export type SetShiftTimesheetAvailabilityResponse = z.infer<
  typeof setShiftTimesheetAvailabilityResponseSchema
>;

export const SET_TIMESHEET_AVAILABILITY_URL = "/v1/shifts/setTimeSheetAvailability";

export function useSetShiftTimesheetAvailability(
  options: UseMutationOptions<
    SetShiftTimesheetAvailabilityResponse,
    AxiosError,
    SetShiftTimesheetAvailabilityRequest
  > = {}
): UseMutationResult<
  SetShiftTimesheetAvailabilityResponse,
  AxiosError,
  SetShiftTimesheetAvailabilityRequest
> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params) => {
      const response = await post({
        url: SET_TIMESHEET_AVAILABILITY_URL,
        data: params,
        requestSchema: setShiftTimesheetAvailabilityRequestSchema,
        responseSchema: setShiftTimesheetAvailabilityResponseSchema,
      });

      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.SET_TIMESHEET_AVAILABILITY_FAILURE,
    },
    ...options,
    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries({
        queryKey: [
          `${environmentConfig.REACT_APP_BASE_API_URL}${GET_WORKER_UNVERIFIED_SHIFTS_PATH}`,
        ],
      });
      return options.onSuccess?.(data, variables, context);
    },
  });
}
